<template>
  <div class="slideshow">
    <div class="container slideshow-wrapper">
      <div class="page-header flex">
        <div class="page-header-content">
          <h1>The Better Way <br />to Start Your Business</h1>
          <p>
            The essence of creating every business is to add value to lives
            while generating returns. At Exclusive UX Designs, we create digital
            solutions that makes it easy to realize the essence of your business
            within a little time.
          </p>
          <router-link :to="{ name: 'Projects' }">
            <button
              type="button"
              name="button"
              class="btn-style blue txt-white"
            >
              See Our Projects
            </button>
          </router-link>
        </div>
        <img src="~@/assets/images/laptop.png" alt="" />
      </div>
    </div>
    <div class="bottom-shape">
      <img src="~@/assets/images/page-shape.png" alt="" />
    </div>
  </div>
  <div class="why">
    <div class="container section-space why-wrapper flex">
      <div class="why-left">
        <div class="key-features flex">
          <div class="first-column flex">
            <div class="key-box flex-column-start">
              <img src="~@/assets/images/rocket.svg" alt="Startup" />
              <span>Satisfaction Guarantee</span>
              <p>
                We will ensure that your final product is up to or above the
                competition.
              </p>
            </div>
          </div>
          <div class="second-column">
            <div class="key-box flex-column-start">
              <img src="~@/assets/images/custom-design.svg" alt="Startup" />
              <span>Custom Designs</span>
              <p>
                Perfection is what we put into the designs and development of
                your projects.
              </p>
            </div>
            <div class="key-box flex-column-start">
              <img src="~@/assets/images/responsive-1.svg" alt="Startup" />
              <span>Web Consulting</span>
              <p>
                Share your idea with us and we will draw up a strategy to make
                your project a success.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="why-right">
        <div class="circle"></div>
        <section class="title left">
          <h3>Why Choose Us?</h3>
          <p>Unleashing the full potentials of your project.</p>
        </section>
        <p>
          You've devoted your time, money, and hard work to creating a project
          that will change the world - why settle for an agency that does
          anything less? Whether you are an established business wanting a
          rebrand to breathe new life into your organization or a startup
          looking to launch your innovative idea, we create digital solutions
          that help realize the full potential of every project.
        </p>
        <p>
          At Exclusive UX Designs, we understand that excellent web design is
          aesthetic because websites are often the first impression given to
          potential customers. We, therefore, develop great designs that create
          the perfect impression in the mind of your audience.
        </p>
      </div>
    </div>
  </div>
  <div class="services">
    <!-- <div class="services-shape">
        <img src="~@/assets/images/waves-shape.png" alt="">
      </div> -->
    <section class="title">
      <h3>Services</h3>
      <p>Unleashing the full potentials of your project.</p>
    </section>
    <div class="container services-wrapper flex-wrap">
      <div class="services-box flex-column-center">
        <img src="~@/assets/images/rocket.svg" alt="Startup" />
        <span>Startup</span>
        <p>
          We develop and implement marketing campaigns that make it easier to
          launch your business / product or idea.
        </p>
      </div>
      <div class="services-box flex-column-center">
        <img src="~@/assets/images/edit-tools.svg" alt="Startup" />
        <span>Websites Design</span>
        <p>
          An attractive design is part of the success of your business. At
          Exclusive UX Designs we guarantee that your customer's experience will
          be the best.
        </p>
      </div>
      <div class="services-box flex-column-center">
        <img src="~@/assets/images/repair.svg" alt="Startup" />
        <span>Websites Development</span>
        <p>
          The design and development of your projects with the most innovative
          technologies is our highest and only priority.
        </p>
      </div>
      <div class="services-box flex-column-center">
        <img src="~@/assets/images/tabs.svg" alt="Startup" />
        <span>Mobile Apps</span>
        <p>
          Here on Exclusive UX Designs, we focus on building mobile apps that
          are simple, super responsive and easy to operate for the end user.
        </p>
      </div>
      <div class="services-box flex-column-center">
        <img src="~@/assets/images/web-search-engine.svg" alt="Startup" />
        <span>Search Engine Optimization</span>
        <p>
          Your project is your life so we offer you the possibility to share it
          with your valued clients. Let us make your project the best friend
          Google can have.
        </p>
      </div>
      <div class="services-box flex-column-center">
        <img src="~@/assets/images/contact.svg" alt="Startup" />
        <span>Email Marketing</span>
        <p>
          Keep your customers connected to your business through email campaigns
          that are able to establish a long relationship between you and your
          audience.
        </p>
      </div>
    </div>
  </div>
  <div class="reviews section-space">
    <section class="title">
      <h3>Google Reviews</h3>
      <p>Don't just take our word for it, see what our customers are saying.</p>
    </section>
    <div class="container reviews-wrapper">
      <div class="elfsight-app-fe73e4de-eb0b-4a37-9b9d-151035dbdb4b"></div>
    </div>
  </div>
  <div class="business-areas section-space">
    <div class="areas-content container">
      <section class="title text-center">
        <h3>Industries We Have Worked With</h3>
        <p>Unleashing the full potentials of your project.</p>
      </section>
      <div class="areas flex-wrap">
        <div class="areas-box flex-column-center">
          <i class="flaticon-dish"></i>
          <p>Restaurants</p>
        </div>
        <div class="areas-box flex-column-center">
          <i class="flaticon-condominium"></i>
          <p>Condominiums</p>
        </div>
        <div class="areas-box flex-column-center">
          <i class="flaticon-crane"></i>
          <p>Construction</p>
        </div>
        <div class="areas-box flex-column-center">
          <i class="flaticon-car"></i>
          <p>Car Dealers</p>
        </div>
        <div class="areas-box flex-column-center">
          <i class="flaticon-air-conditioner"></i>
          <p>Air Conditioning</p>
        </div>
        <div class="areas-box flex-column-center">
          <i class="flaticon-camera"></i>
          <p>Photography</p>
        </div>
      </div>
    </div>
    <div class="areas-bg">
      <img src="~@/assets/images/areas-bg.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home'
}
</script>
