
import { ref, onMounted, onBeforeUnmount, defineComponent } from 'vue'

export default defineComponent({
  name: 'HeaderNavBarComponent',
  setup() {
    const header = ref<HTMLElement | any>(null)
    const mobileNav = ref(false)
    const onScroll = () => {
      // // Get the current scroll position
      // const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      // // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      // if (currentScrollPosition < 0) {
      //   return
      // }
      // // Here we determine whether we need to show or hide the navbar
      // this.showNavbar = currentScrollPosition < this.lastScrollPosition
      // // Set the current scroll position as the last scroll position
      // this.lastScrollPosition = currentScrollPosition
      // if (
      //   document.body.scrollTop > 80 ||
      //   document.documentElement.scrollTop > 80
      // ) {
      //   //document.getElementById("navbar").style.top = "0";
      //   header.classList.add('header-scroll')
      //   // header.classList.toggle('header-scroll')
      //   console.log('SI')
      // } else {
      //   console.log('No')
      //   // header.classList.toggle('header-scroll')
      //   //document.getElementById("navbar").style.top = "-50px";
      //   header.classList.remove('header-scroll')
      // }
      document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
        ? header.value.classList.add('header-scroll')
        : header.value.classList.remove('header-scroll')
    }
    onMounted(() => {
      window.addEventListener('scroll', onScroll)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll)
    })

    const changeMenuState = () => {
      mobileNav.value = !mobileNav.value
    }

    return { header, mobileNav, changeMenuState }
  }
})
