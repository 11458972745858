
// import CookieLaw from 'vue-cookie-law'
import { defineComponent } from 'vue'
import HeaderNavBarComponent from '@/components/HeaderNavBarComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
// import { mapState } from 'vuex'
export default defineComponent({
  components: {
    HeaderNavBarComponent,
    FooterComponent
    // CookieLaw
  }
  // computed: {
  //   ...mapState({
  //     isLoading: state => state.isLoading,
  //     message: state => state.message
  //   })
  // }
})
