import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import store from './store'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client/core'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { defineRule } from 'vee-validate'
import { required, email, min } from '@vee-validate/rules'

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: 'http://localhost:4000/graphql'
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache
})

// const httpLink = new HttpLink({
//   // You should use an absolute URL here
//   uri: 'http://localhost:4000/graphql'
// })

// // using the ability to split links, you can send data to each link
// // depending on what kind of operation is being sent
// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const { kind } = getMainDefinition(query)
//     return kind === 'OperationDefinition'
//   },
//   httpLink
// )

// // Create the apollo client
// const apolloClient = new ApolloClient({
//   link,
//   cache: new InMemoryCache(),
//   connectToDevTools: true
// })

createApp(App)
  .provide(DefaultApolloClient, apolloClient)
  // .use(store)
  .use(router)
  .mount('#app')
