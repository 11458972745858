import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "About" */ '@/views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "Contact" */ '@/views/ContactView.vue')
  },
  {
    path: '/policy',
    name: 'Policy',
    component: () =>
      import(/* webpackChunkName: "Policy" */ '@/views/PolicyView.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () =>
      import(/* webpackChunkName: "Services" */ '@/views/ServicesView.vue')
  },
  {
    path: '/portafolio',
    name: 'Portafolio',
    component: () =>
      import(/* webpackChunkName: "Portafolio" */ '@/views/PortafolioView.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () =>
      import(/* webpackChunkName: "Projects" */ '@/views/ProjectsView.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () =>
      import(/* webpackChunkName: "Terms" */ '@/views/TermsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  // window.location.href
  next()
})

export default router
