<template>
  <!-- <div class="loading" v-if="isLoading">
    <div class="loading-content">
      <img src="~@/assets/images/loading.svg" alt="Loading..." />
    </div>
  </div>
  <transition name="show-message-transition">
    <div v-if="message.state" :class="`${message.type}-message`">
      <div class="container">
        <div>
          <p>{{ message.text }}</p>
        </div>
      </div>
    </div>
  </transition> -->
  <HeaderNavBarComponent />
  <router-view />
  <FooterComponent />
  <!-- <cookie-law theme="dark-lime">
    <div slot-scope="props">
      <p>
        This website stores 🍪 (cookies) on your computer. These cookies are
        used to collect information about how you interact with our website and
        allow us to remember you. We use this information in order to improve
        and customize your browsing experience and for analytics and metrics
        about our visitors both on this website and other media. To find out
        more about the <router-link to="/cookies">cookies</router-link> we use,
        see our <router-link to="/privacy">Privacy Policy</router-link>.
      </p>
      <button class="skew" @click="props.accept"><span>Accept</span></button>
      <button class="skew" @click="props.close"><span>Decline</span></button>
    </div>
  </cookie-law> -->
</template>
<script lang="ts">
// import CookieLaw from 'vue-cookie-law'
import { defineComponent } from 'vue'
import HeaderNavBarComponent from '@/components/HeaderNavBarComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
// import { mapState } from 'vuex'
export default defineComponent({
  components: {
    HeaderNavBarComponent,
    FooterComponent
    // CookieLaw
  }
  // computed: {
  //   ...mapState({
  //     isLoading: state => state.isLoading,
  //     message: state => state.message
  //   })
  // }
})
</script>

<style>
@import '~normalize.css';
/* @import './assets/css/normalize.css'; */
@import './assets/css/main.css';
@import './assets/font/flaticon.css';
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999;
}

.loading .loading-content {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-message-transition-enter-active,
.show-message-transition-leave-active {
  transition: opacity 0.5s;
}
.show-message-transition-enter,
.show-message-transition-leave-to {
  opacity: 0;
}

.success-message {
  display: block;
  background: #e4f5bc;
  width: 100%;
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #c8eb79;
  opacity: 0.98;
}
.success-message > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-message > div div {
  display: flex;
  align-items: center;
}
.success-message > div div {
  font-weight: bold;
  font-size: 0.9em;
}
.success-message > div div img {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.error-message {
  display: block;
  background: #eddada;
  width: 100%;
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #ddaab4;
  opacity: 0.98;
}
.error-message > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-message > div div {
  display: flex;
  align-items: center;
}
.error-message > div div {
  font-weight: bold;
  font-size: 0.9em;
}
.error-message > div div img {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.info-message {
  display: block;
  background: #d6eb82;
  width: 100%;
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #b5c76c;
  opacity: 0.98;
}
.info-message > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-message > div div {
  display: flex;
  align-items: center;
}
.info-message > div div {
  font-weight: bold;
  font-size: 0.9em;
}
.info-message > div div img {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
</style>
