<template>
  <footer>
    <div class="footer-content container flex-column-center center">
      <div class="top-footer">
        <p>Let's work together</p>
        <span>hello@exclusiveuxdesigns.com</span>
      </div>
    </div>
    <div class="footer-info container flex-center">
      <div class="footer-contact flex-center">
        <div class="flex">
          <i class="flaticon-phone-call-1"></i>
          <p>+1 (786) 410-6196</p>
        </div>
        <div class="flex">
          <i class="flaticon-envelope"></i>
          <p>info@exclusiveuxdesigns.com</p>
        </div>
      </div>
    </div>
  </footer>
  <div class="footer-copyright">
    <div class="flex container">
      <div class="rights">
        <p>&copy; {{ Year }} Exclusive UX Designs, LLC.</p>
      </div>
      <div class="legal">
        <p class="center">
          <router-link :to="{ name: 'Policy' }">Privacy Policy</router-link> |
          <router-link :to="{ name: 'Terms' }">Terms & Conditions</router-link>
        </p>
      </div>
      <div class="social-media flex-end">
        <p>Follow us:</p>
        <div>
          <i class="flaticon-facebook-logo"></i>
        </div>
        <div>
          <i class="flaticon-instagram-logo"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ref } from 'vue'
import moment from 'moment'
export default {
  setup() {
    const Year = ref(moment().format('YYYY'))
    return { Year }
  }
}
</script>
