<template>
  <div>
    <link
      href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap"
      rel="stylesheet"
    />
    <header ref="header" class="flex">
      <div class="container header-wrapper flex">
        <img src="~@/assets/images/logo.png" alt="Exclusive UX Designs' Logo" />
        <nav>
          <ul class="flex">
            <li><router-link :to="{ name: 'Home' }">Home</router-link></li>
            <li>
              <router-link :to="{ name: 'Services' }">Services</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Projects' }">Projects</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'About' }">About Us</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Contact' }">Contact Us</router-link>
            </li>
          </ul>
        </nav>
        <div class="header-mobile-icons flex">
          <div class="flex-column-center">
            <a href="tel:786.312.0625">
              <i class="flaticon-phone"></i>
              <p>Call</p>
            </a>
          </div>
          <div @click="changeMenuState">
            <i class="flaticon-menu"></i>
            <p>Menu</p>
            <!-- </div class="flex-column-center"> -->
          </div>
        </div>
        <!-- <router-link :to="{ name: 'Contact' }"> -->
        <button type="button" name="button" class="btn-style blue txt-white">
          Get Started
        </button>
        <!-- </router-link> -->
      </div>
    </header>
    <div v-if="mobileNav" class="mobile-nav">
      <div @click="changeMenuState" class="close-nav">
        <i class="flaticon-cancel"></i>
      </div>
      <div class="mobile-nav-content">
        <ul class="">
          <li @click="changeMenuState">
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </li>
          <li @click="changeMenuState">
            <router-link :to="{ name: 'Services' }">Services</router-link>
          </li>
          <li @click="changeMenuState">
            <router-link :to="{ name: 'Projects' }">Projects</router-link>
          </li>
          <li @click="changeMenuState">
            <router-link :to="{ name: 'About' }">About Us</router-link>
          </li>
          <li @click="changeMenuState">
            <router-link :to="{ name: 'Contact' }">Contact Us</router-link>
          </li>
        </ul>
      </div>
      <div class="mobile-nav-info">
        <p>More Information</p>
        <ul class="">
          <li class="flex-start">
            <i class="flaticon-phone-call-1"></i
            ><a href="tel:786.312.0625">786.312.0625</a>
          </li>
          <li class="flex-start">
            <i class="flaticon-envelope"></i
            ><a href="mailto:info@exclusiveuxdesigns.com"
              >info@exclusiveuxdesigns.com</a
            >
          </li>
        </ul>
      </div>
      <div class="mobile-nav-info">
        <p>Follow Us</p>
        <ul class="">
          <li class="flex-start">
            <i class="flaticon-facebook-logo"></i
            ><a
              href="https://www.facebook.com/exclusiveuxdesigns"
              target="_blank"
              >Facebook</a
            >
          </li>
          <li class="flex-start">
            <i class="flaticon-instagram-logo"></i
            ><a
              href="https://www.instagram.com/exclusiveuxdesigns/"
              target="_blank"
              >Instagram</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, onBeforeUnmount, defineComponent } from 'vue'

export default defineComponent({
  name: 'HeaderNavBarComponent',
  setup() {
    const header = ref<HTMLElement | any>(null)
    const mobileNav = ref(false)
    const onScroll = () => {
      // // Get the current scroll position
      // const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      // // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      // if (currentScrollPosition < 0) {
      //   return
      // }
      // // Here we determine whether we need to show or hide the navbar
      // this.showNavbar = currentScrollPosition < this.lastScrollPosition
      // // Set the current scroll position as the last scroll position
      // this.lastScrollPosition = currentScrollPosition
      // if (
      //   document.body.scrollTop > 80 ||
      //   document.documentElement.scrollTop > 80
      // ) {
      //   //document.getElementById("navbar").style.top = "0";
      //   header.classList.add('header-scroll')
      //   // header.classList.toggle('header-scroll')
      //   console.log('SI')
      // } else {
      //   console.log('No')
      //   // header.classList.toggle('header-scroll')
      //   //document.getElementById("navbar").style.top = "-50px";
      //   header.classList.remove('header-scroll')
      // }
      document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
        ? header.value.classList.add('header-scroll')
        : header.value.classList.remove('header-scroll')
    }
    onMounted(() => {
      window.addEventListener('scroll', onScroll)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll)
    })

    const changeMenuState = () => {
      mobileNav.value = !mobileNav.value
    }

    return { header, mobileNav, changeMenuState }
  }
})
</script>
